// Variable Overwites
$blue1: var(--brandColorPrimary);
$blue2: var(--brandColorPrimary);
$blue3: var(--brandColorPrimary2);
$blue4: var(--brandColorPrimary3);
$blue5: var(--brandColorPrimary4);

$red1: var(--brandColorPrimary);
$red2: var(--brandColorPrimary);
$red3: var(--brandColorPrimary2);
$red4: var(--brandColorPrimary3);
$red5: var(--brandColorPrimary4);

$pt-link-color: var(--bodyText);
$pt-intent-primary: var(--brandColorPrimary);

$pt-font-size: 16px;
$pt-font-size-small: 14px;
$pt-font-size-large: 18px;

$pt-grid-size: 11px;
$pt-border-radius: 6px;

$pt-font-family: "Avenir Next LT Pro", sans-serif;

// Blue Print Core
@import "~@blueprintjs/core/src/blueprint.scss";
@import "~@blueprintjs/table/lib/css/table.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

@font-face {
  font-family: "Avenir Next LT Pro";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/AvenirNextLTPro-Regular.otf") format("opentype");
}

@font-face {
  font-family: "Avenir Next LT Pro";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/AvenirNextLTPro-Bold.otf") format("opentype");
}

@font-face {
  font-family: "Avenir Next LT Pro";
  font-style: normal;
  font-weight: normal;
  src: url("/assets/fonts/AvenirNextLTPro-Regular.otf") format("opentype");
}

// Dialog Overwrites
.bp3-dialog {
  background: white;
  padding-bottom: 0;
  border-radius: 5px;
  margin: 0.5rem;

  .bp3-dialog-header {
    border-radius: 5px 5px 0 0;
    padding: 0.7rem 0.7rem 0.6rem 1.2rem;
  }
}

// Toast Overwrites
.bp3-toast.bp3-intent-danger {
  background: #e53935;
}

// Textarea
textarea.bp3-input {
  border: 1px solid var(--borderColor);
}

// DateInput
.bp3-menu-item.bp3-disabled {
  display: none;
}

// Tags
.bp3-tag.bp3-large,
.bp3-large .bp3-tag {
  min-height: 28px;
  padding: 3px 9px 0;
}

// File input
.bp3-file-input {
  height: 38px;
}

.bp3-file-upload-input {
  height: 43px;
  padding-top: 6px;
  border: 1px solid var(--borderColor);

  &::after {
    top: 3px;
    right: 3px;
  }
}

// Alerts
.bp3-alert-footer {
  margin: 25px 0 20px;
}

.bp3-button.bp3-intent-primary {
  box-shadow: none;
}
